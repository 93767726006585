import React from "react";

// IMPORT OTHERS HERE //
import appStyles from "./Contact.module.scss";

const Contact = () => {
  return (
    <main className={appStyles["main-cnt"]} id="contact">
      <div>Contact</div>
    </main>
  );
};

export default Contact;
