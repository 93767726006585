/* eslint-disable */
import React from "react";

export const BackendIcon = () => (
  <svg version="1.1" width="16" height="16" viewBox="0 0 16 16">
    <path fill="#b7b7b7" d="M2.1 3.1c0.2 1.3 0.4 1.6 0.4 2.9 0 0.8-1.5 1.5-1.5 1.5v1c0 0 1.5 0.7 1.5 1.5 0 1.3-0.2 1.6-0.4 2.9-0.3 2.1 0.8 3.1 1.8 3.1s2.1 0 2.1 0v-2c0 0-1.8 0.2-1.8-1 0-0.9 0.2-0.9 0.4-2.9 0.1-0.9-0.5-1.6-1.1-2.1 0.6-0.5 1.2-1.1 1.1-2-0.3-2-0.4-2-0.4-2.9 0-1.2 1.8-1.1 1.8-1.1v-2c0 0-1 0-2.1 0s-2.1 1-1.8 3.1z" />
    <path fill="#b7b7b7" d="M13.9 3.1c-0.2 1.3-0.4 1.6-0.4 2.9 0 0.8 1.5 1.5 1.5 1.5v1c0 0-1.5 0.7-1.5 1.5 0 1.3 0.2 1.6 0.4 2.9 0.3 2.1-0.8 3.1-1.8 3.1s-2.1 0-2.1 0v-2c0 0 1.8 0.2 1.8-1 0-0.9-0.2-0.9-0.4-2.9-0.1-0.9 0.5-1.6 1.1-2.1-0.6-0.5-1.2-1.1-1.1-2 0.2-2 0.4-2 0.4-2.9 0-1.2-1.8-1.1-1.8-1.1v-2c0 0 1 0 2.1 0s2.1 1 1.8 3.1z" />
  </svg>
);

export const FrontendIcon = () => (
  <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 511.997 511.997" width="512px" height="512px">
    <g>
      <g>
        <g>
          <path d="M506.76,242.828l-118.4-125.44c-7.277-7.718-19.424-8.07-27.142-0.787c-7.706,7.277-8.064,19.43-0.781,27.142    l105.965,112.256L360.437,368.268c-7.283,7.712-6.925,19.859,0.781,27.142c3.712,3.501,8.454,5.235,13.178,5.235    c5.101,0,10.195-2.022,13.965-6.01l118.4-125.446C513.742,261.785,513.742,250.226,506.76,242.828z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#b7b7b7" />
        </g>
      </g>
      <g>
        <g>
          <path d="M151.566,368.262L45.608,255.999l105.958-112.262c7.277-7.712,6.925-19.866-0.787-27.142    c-7.706-7.277-19.866-6.925-27.142,0.787l-118.4,125.44c-6.982,7.398-6.982,18.963,0,26.362L123.643,394.63    c3.776,4,8.864,6.016,13.965,6.016c4.723,0,9.466-1.741,13.171-5.242C158.498,388.127,158.843,375.974,151.566,368.262z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#b7b7b7" />
        </g>
      </g>
      <g>
        <g>
          <path d="M287.061,52.697c-10.477-1.587-20.282,5.606-21.882,16.083l-56.32,368.64c-1.6,10.483,5.6,20.282,16.083,21.882    c0.986,0.147,1.958,0.218,2.925,0.218c9.325,0,17.504-6.803,18.957-16.301l56.32-368.64    C304.744,64.095,297.544,54.297,287.061,52.697z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#b7b7b7" />
        </g>
      </g>
    </g>
  </svg>
);

export const LinesIcon = () => (
  <svg width="60" height="6" viewBox="0 0 60 6">
    <rect fill="#b7b7b7" width="25.594" height="1.5" />
    <rect id="Rectangle_1_copy_2" fill="#b7b7b7" data-name="Rectangle 1 copy 2" x="34.406" width="25.594" height="1.5" />
    <rect id="Rectangle_1_copy" fill="#b7b7b7" data-name="Rectangle 1 copy" x="27" width="6" height="1.5" />
    <rect id="Rectangle_1_copy_3" fill="#b7b7b7" data-name="Rectangle 1 copy 3" x="13.5" y="4.5" width="25.594" height="1.5" rx="0.5" ry="0.5" />
    <rect id="Rectangle_1_copy_3-2" fill="#b7b7b7" data-name="Rectangle 1 copy 3" x="40.5" y="4.5" width="6" height="1.5" rx="0.5" ry="0.5" />
  </svg>
);

export const BrandLogoIcon = () => (
  <svg viewBox="0 0 500 500">
    <path
      d="M 165.78 112.244 Q 164.822 112.464 163.99 112.613 Q 163.158 112.762 162.263 112.762 Q 161.887 112.762 161.447 112.723 Q 161.007 112.684 160.583 112.543 Q 160.16 112.401 159.798 112.15 Q 159.437 111.899 159.202 111.475 Q 159.265 111.255 159.492 111.004 Q 159.72 110.753 160.018 110.478 Q 160.317 110.203 160.654 109.936 Q 160.992 109.669 161.29 109.403 Q 161.73 109.01 162.083 108.625 Q 162.436 108.241 162.774 107.809 Q 163.111 107.377 163.472 106.836 Q 163.833 106.294 164.289 105.572 Q 164.665 105.572 164.995 105.69 Q 165.325 105.807 165.568 105.988 Q 165.812 106.168 165.953 106.388 Q 166.094 106.608 166.094 106.828 Q 166.094 107 166.063 107.173 Q 166.031 107.346 166 107.503 Q 165.906 107.424 165.78 107.424 Q 165.623 107.424 165.293 107.66 Q 164.964 107.895 164.54 108.264 Q 164.116 108.633 163.637 109.104 Q 163.158 109.575 162.687 110.038 Q 162.216 110.502 161.808 110.91 Q 161.4 111.318 161.117 111.585 Q 161.227 111.663 161.368 111.726 Q 161.51 111.789 161.73 111.836 Q 161.949 111.883 162.279 111.907 Q 162.609 111.93 163.095 111.93 Q 163.409 111.93 163.81 111.899 Q 164.21 111.867 164.603 111.82 Q 164.995 111.773 165.348 111.726 Q 165.702 111.679 165.937 111.632 Q 165.953 111.679 165.953 111.758 Q 165.953 111.883 165.898 112.024 Q 165.843 112.166 165.78 112.244 Z"
      transform="matrix(17.423914, 0.042679, -0.05165, 21.086479, -2757.236816, -2071.771973)"
      fill="#C159D6"
    />
    <path
      d="M 177.79 106.781 Q 177.79 107.644 177.358 108.429 Q 176.927 109.214 176.197 109.803 Q 175.467 110.392 174.485 110.737 Q 173.504 111.082 172.405 111.082 Q 172.013 111.082 171.785 110.98 Q 171.557 110.878 171.275 110.58 Q 170.49 112.291 169.995 113.728 Q 169.501 115.164 169.218 116.515 Q 168.621 116.373 168.245 116.02 Q 167.868 115.667 167.554 115.133 Q 167.711 114.835 167.931 114.348 Q 168.15 113.861 168.464 113.147 Q 168.778 112.433 169.194 111.436 Q 169.611 110.439 170.176 109.104 Q 170.427 108.492 170.615 108.037 Q 170.804 107.581 170.945 107.181 Q 171.086 106.781 171.204 106.388 Q 171.322 105.996 171.447 105.525 Q 170.71 105.76 170.089 106.074 Q 169.469 106.388 169.061 106.734 Q 168.92 106.639 168.771 106.451 Q 168.621 106.263 168.496 106.035 Q 168.37 105.807 168.276 105.572 Q 168.182 105.336 168.166 105.148 Q 168.621 104.834 169.297 104.567 Q 169.972 104.3 170.741 104.104 Q 171.51 103.908 172.334 103.798 Q 173.159 103.688 173.912 103.688 Q 174.807 103.688 175.529 103.915 Q 176.252 104.143 176.754 104.551 Q 177.256 104.959 177.523 105.525 Q 177.79 106.09 177.79 106.781 Z M 174.996 104.897 Q 174.399 104.897 173.771 104.991 L 173.959 105.273 Q 173.802 105.478 173.567 105.886 Q 173.331 106.294 173.049 106.867 Q 172.766 107.44 172.429 108.147 Q 172.091 108.853 171.714 109.638 Q 172.939 109.638 173.944 109.41 Q 174.949 109.183 175.663 108.782 Q 176.377 108.382 176.778 107.848 Q 177.178 107.314 177.178 106.702 Q 177.178 105.886 176.628 105.391 Q 176.079 104.897 174.996 104.897 Z"
      transform="matrix(17.423914, 0.042679, -0.05165, 21.086479, -2757.236816, -2071.771973)"
      fill="#C159D6"
    />
    <path
      d="M 180.585 106.608 Q 181.26 106.796 181.857 107.087 Q 182.453 107.377 182.979 107.731 Q 183.505 108.084 183.984 108.5 Q 184.463 108.916 184.918 109.355 Q 185.264 109.701 185.578 110.243 Q 185.892 110.784 185.892 111.412 Q 185.892 111.648 185.845 111.852 Q 185.64 111.977 185.319 112.017 Q 184.997 112.056 184.604 112.056 Q 184.149 112.056 183.662 112.032 Q 183.176 112.009 182.752 112.009 Q 182.186 112.009 181.692 112.056 Q 181.197 112.103 180.664 112.205 Q 180.13 112.307 179.525 112.464 Q 178.921 112.621 178.12 112.841 Q 177.602 112.119 177.602 111.601 Q 177.602 111.13 178.034 110.902 Q 178.466 110.674 179.188 110.643 Q 179.706 110.627 180.452 110.604 Q 181.197 110.58 181.959 110.557 Q 182.72 110.533 183.403 110.517 Q 184.086 110.502 184.494 110.502 Q 184.463 110.36 184.408 110.235 Q 184.353 110.109 184.22 109.944 Q 184.086 109.779 183.874 109.552 Q 183.662 109.324 183.333 108.994 Q 183.019 108.696 182.634 108.39 Q 182.249 108.084 181.825 107.817 Q 181.401 107.55 180.946 107.33 Q 180.491 107.11 180.051 106.969 Q 180.098 106.843 180.279 106.734 Q 180.459 106.624 180.585 106.608 Z"
      transform="matrix(17.423914, 0.042679, -0.05165, 21.086479, -2757.236816, -2071.771973)"
      fill="#C159D6"
    />
  </svg>
);

export const CrossplatformMobileAppIcon = () => (
  <svg id="Layer_1" width="160" height="160" viewBox="176 176 160 160" enable-background="new 176 176 160 160">
    <g>
      <g id="Apple_1_">
        <path d="M289.028 179.581c.325 17.452-11.838 30.661-28.94 36.421h.392v-.418c0 0-1.6-.148-1.6-.421V200.6c5.44-12.235 13.577-21.848 26.732-24.552.961-.178 1.687.204 2.434.843C288.829 177.514 289.028 178.578 289.028 179.581z" />
        <path d="M321.923 288.93c-7.241 21.776-21.117 44.993-32.895 44.993-6.37.073-12.68-1.252-18.485-3.878-3.47-1.685-6.731-2.604-10.586-2.699-.538.012-1.077.041-1.077.095V222.023c0 .042.539.06 1.077.072 4.899-.095 9.469-1.168 13.945-3.16 4.852-2.141 9.956-3.304 15.259-3.417 10.815.107 21.044 4.649 28.416 12.561.161.178.27.368.389.575.905 1.571.351 3.588-1.227 4.489-9.003 5.259-14.544 14.902-14.544 25.328-.06 11.534 6.887 21.942 17.561 26.313.012.006.022.006.034.011C321.522 285.347 322.475 287.198 321.923 288.93z" />
      </g>
      <g>
        <path fill="#32de84" d="M212.54 230.722h37.06v-32.763c-3.84.231-9.99 1.299-14.485 3.049l-1.592-2.421-1.504-2.383-3.734-5.67c-.438-.652-1.291-.825-1.949-.392-.646.433-.808 1.309-.381 1.955l3.535 5.287 1.568 2.349 1.595 2.547C220.651 207.871 212.54 217.922 212.54 230.722zM230.918 214.879c0-2.343 1.897-4.236 4.247-4.236 2.337.0 4.234 1.893 4.234 4.236.0 2.341-1.897 4.245-4.234 4.245C232.815 219.124 230.918 217.221 230.918 214.879z" />
        <path fill="#32de84" d="M212.16 234.882V298.3c0 4.939 4.395 8.902 9.329 8.902h6.465c-.213.0.847 1.595.847 2.406v17.915c0 4.681 3.793 8.479 8.48 8.479 4.679.0 8.48-3.799 8.48-8.479v-17.915c0-.817-1.296-2.406-1.51-2.406h5.35v-72.32H212.16z" />
        <path fill="#32de84" d="M189.92 244.57v36.288c0 4.682 3.794 8.482 8.475 8.482 4.688.0 8.482-3.801 8.482-8.482v-36.284c0-4.691-3.793-8.487-8.482-8.487C193.714 236.086 189.92 239.882 189.92 244.57z" />
      </g>
    </g>
  </svg>
);

export const ScrollMouse = () => {
  return (
    <svg viewBox="206.384 112.329 30.519 24.248">
      <g transform="matrix(0.1, 0, 0, -0.1, 939.597337, 593.758408)" fill="#000000" stroke="none" />
      <g transform="matrix(0.1, 0, 0, -0.1, 939.597337, 593.758408)" fill="#000000" stroke="none" />
      <path d="M 215.105 134.755 L 215.13 134.755 C 218.989 134.755 222.13 131.615 222.13 127.755 L 222.13 121.755 C 222.13 117.914 219.003 114.781 215.149 114.755 L 215.089 114.755 C 211.249 114.777 208.13 117.912 208.13 121.755 L 208.13 127.755 C 208.13 131.615 211.259 134.755 215.105 134.755 Z M 210.13 121.755 C 210.134 119.001 212.361 116.767 215.115 116.755 C 217.88 116.761 220.13 119.004 220.13 121.755 L 220.13 127.755 C 220.13 130.512 217.887 132.755 215.13 132.755 L 215.105 132.755 C 212.316 132.755 210.13 130.559 210.13 127.755 L 210.13 121.755 Z" />
      <path d="M 214.13 118.755 L 216.13 118.755 L 216.13 124.755 L 214.13 124.755 L 214.13 118.755 Z" />
      <path d="M 231.275 120.691 L 230.586 119.755 L 228.497 122.598 L 228.497 116.249 L 227.523 116.249 L 227.523 122.598 L 225.434 119.755 L 224.746 120.691 L 228.01 125.133 L 231.275 120.691 Z" transform="matrix(-1, 0, 0, -1, 456.020996, 241.382004)" />
      <path d="M 231.271 128.773 L 230.582 127.837 L 228.493 130.68 L 228.493 124.331 L 227.519 124.331 L 227.519 130.68 L 225.43 127.837 L 224.742 128.773 L 228.006 133.215 L 231.271 128.773 Z" />
    </svg>
  );
};

export const ArrowKeys = () => {
  return (
    <svg viewBox="210.856 114.712 20.957 14.98">
      <path d="M 219.773 126.593 L 222.862 126.593 L 221.317 124.56 L 219.773 126.593 Z" transform="matrix(-1, 0, 0, -1, 442.634995, 251.153)" />
      <path d="M 223.503 122.672 L 219.159 122.672 C 218.817 122.672 218.538 122.951 218.538 123.296 L 218.538 127.659 C 218.538 128.003 218.817 128.282 219.159 128.282 L 223.503 128.282 C 223.844 128.282 224.123 128.003 224.123 127.659 L 224.123 123.296 C 224.123 122.951 223.844 122.672 223.503 122.672 Z M 219.159 127.659 L 219.159 123.296 L 223.503 123.296 L 223.503 127.659 L 219.159 127.659 Z" transform="matrix(-1, 0, 0, -1, 442.660995, 250.953995)" />
      <path d="M 212.935 126.447 L 216.024 126.447 L 214.479 124.414 L 212.935 126.447 Z" transform="matrix(0, -1, 1, 0, 89.049, 339.91)" />
      <path d="M 216.752 122.639 L 212.408 122.639 C 212.066 122.639 211.787 122.918 211.787 123.263 L 211.787 127.626 C 211.787 127.97 212.066 128.249 212.408 128.249 L 216.752 128.249 C 217.093 128.249 217.372 127.97 217.372 127.626 L 217.372 123.263 C 217.372 122.918 217.093 122.639 216.752 122.639 Z M 212.408 127.626 L 212.408 123.263 L 216.752 123.263 L 216.752 127.626 L 212.408 127.626 Z" transform="matrix(0, -1, 1, 0, 89.135502, 340.023495)" />
      <path d="M 219.888 119.515 L 222.977 119.515 L 221.432 117.482 L 219.888 119.515 Z" />
      <path d="M 223.593 115.793 L 219.249 115.793 C 218.907 115.793 218.628 116.072 218.628 116.417 L 218.628 120.78 C 218.628 121.124 218.907 121.403 219.249 121.403 L 223.593 121.403 C 223.934 121.403 224.213 121.124 224.213 120.78 L 224.213 116.417 C 224.213 116.072 223.934 115.793 223.593 115.793 Z M 219.249 120.78 L 219.249 116.417 L 223.593 116.417 L 223.593 120.78 L 219.249 120.78 Z" />
      <path d="M 226.781 126.579 L 229.87 126.579 L 228.325 124.546 L 226.781 126.579 Z" transform="matrix(0, 1, -1, 0, 353.888, -102.763)" />
      <path d="M 230.4 122.745 L 226.056 122.745 C 225.714 122.745 225.435 123.024 225.435 123.369 L 225.435 127.732 C 225.435 128.076 225.714 128.355 226.056 128.355 L 230.4 128.355 C 230.741 128.355 231.02 128.076 231.02 127.732 L 231.02 123.369 C 231.02 123.024 230.741 122.745 230.4 122.745 Z M 226.056 127.732 L 226.056 123.369 L 230.4 123.369 L 230.4 127.732 L 226.056 127.732 Z" transform="matrix(0, 1, -1, 0, 353.7775, -102.677502)" />
    </svg>
  );
};

export const HoverIllustration = () => (
  <svg viewBox="0 0 32 32" id="click">
    <path d="M16.1 8.43V5.36a1 1 0 1 1 2 0v3.07a1 1 0 0 1-2 0Zm7 3.49a1 1 0 0 0 .71-.29L26 9.46a1 1 0 0 0 0-1.41 1 1 0 0 0-1.41 0l-2.17 2.17a1 1 0 0 0 .7 1.7ZM10.37 22.26 8.2 24.43a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l2.17-2.17a1 1 0 1 0-1.42-1.42ZM9.58 17a1 1 0 0 0-1-1H5.52a1 1 0 0 0 0 2h3.06a1 1 0 0 0 1-1Zm0-8.9A1 1 0 0 0 8.2 9.46l2.17 2.17a1 1 0 0 0 .71.29 1 1 0 0 0 .71-.29 1 1 0 0 0 0-1.41Zm17.57 19.3a1 1 0 0 1-1.41 0l-3.63-3.63-2.58 3.35a1 1 0 0 1-.79.39h-.17a1 1 0 0 1-.78-.68L14.28 15.7a1 1 0 0 1 1.26-1.26L26.61 18a1 1 0 0 1 .31 1.75l-3.35 2.58 3.62 3.62a1 1 0 0 1 0 1.4Zm-5.89-5.8.07-.06.07-.06 2.74-2.11L16.73 17l2.37 7.4Z"></path>
  </svg>
);
